import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aquarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Aquarius Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aquarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aquarius </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aquarius Woman</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aquarius Woman</h1>
                <h2 className="text-sm md:text-base ml-4">Jan 20 - Feb 18</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Let's understand the personality traits of an Aquarius woman. </p> <br/>
The Aquarius women are intellectual, self-dependent, and friendly from all zodiacs. They have excellent stability that helps them conquer even the most difficult tasks. They are genuine and have strong beliefs but are impartial and forgiving of others' viewpoints. 
<br/><br/>
Read on to know more about other qualities of the Aquarius women. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Aquarius Woman Good Traits</p><br/>
Aquarius women are predictors. Aquarius women also feel themselves problem-solvers. Whenever they notice something wrong, they strongly want to fix it. Plus, they are clever to discuss anything. They are the most exciting individual in any gathering.
<br/><br/>
However, they're not suitable for pretending. If someone is exhausting them, they aren't going to stick around to chitchat. Aquarius women won't waste their time on those who annoy them. They develop nervousness when their minds aren't stable.
<br/><br/>
Aquarius women like to surround themselves with their equals. They're selective about who they allow into their lives because their time is priceless. They refuse to waste it on those who won't deserve it.
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Independent</p><br/>
The Aquarius women are truthful, independent, and empathetic. They consistently try to peek at the bigger picture and will not shy away from bearing the commitment to work for the improvement of society. 
<br/><br/>
Besides, here you can get the complete Life Predictions Report in detail to understand your life and destiny. Renowned for their intelligence, perspicuity, and loving nature, it is not challenging for them to make friends. 
<br/><br/>
They are famous in their social circles. But they accept while building a personal relationship as they shy away from emotional commitment.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Aquarius Woman in Love</p><br/>
Aquarius women are sensitive. They take time to warm up to new individuals because they don't want to get hurt. Most of their connections start as friendships. After a while, once they know to trust you, the friendship can grow into something more profound. Until then, you presumably won't get much info out of them. Although they are talkative, they won't tell their deepest feelings. They will keep you at arm's length if they find you guilty.
<br/><br/>
Aquarius women are attracted to people who are as unique as they are. They don't want somebody who sticks to the trends. Aquarius couldn't care less about what's stylish, trendy, or classic. They do whatever makes them the most comfortable at the moment. This zodiac sign is drawn to other individuals like them who don't like to play by the rules. They altogether avoid the physical appearance related to their clothing. 
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Patience</p> <br/>
Aquarius women tend to be unreliable and unworkable, and keeping up with them may become too difficult for others. One requires a lot of tolerance and patience to comprehend the Aquarius women. But when they are in an emotional space, getting along with them becomes easy. 
<br/><br/>
You can talk to our best astrologer to get solutions for your most critical problems, as he would analyze based on your Birth Chart and deliver you instant remedies. 
<br/><br/>
Instead of searching ineffective from different places, It's always better to speak to an astrologer directly! An Aquarius-women marks the standard high and hopes for the exact from others. 
<br/><br/>
This is why the Aquarius women get emotionally dissatisfied quickly. False commitments and below-par performance bother them very much. Moreover, they don't pardon easily.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Aquarius Woman Behavior</p><br/>
Aquarius is considered strange and unique. This is because they dress in weird ways and listen to bizarre music. They won't follow the trend. Their bizarre personality can drive some people away. 
<br/><br/>
However, Aquarius is fabulous in its way. As much as they adore people, they also value having their space. Besides, they don't like to waste time with shallow people who always care about appearances. They would rather be their quirky self than play pretends.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Intellectuals</p><br/>
Aquarius women are born intellectuals and have excellent leadership qualities. They value sharing knowledge, which permits them to expand their learning. Team-work affects them a lot. These women are significant support to the firms they work for. 
<br/><br/>
Experienced and friendly positions and governance attract them more than wealth. Though they prefer a relaxed lifestyle, they rarely choose money over things. 
<br/><br/>
Not the ones to carry half-hearted efforts, these women love to explore their opportunities and achieve incredible heights irrespective of the possible financial gains. Moreover, if you wish to know about your development prospects on the career front? 
 <br/><br/>
Get a fully personalized Natal Chart-based Remedial Answers for Career report.
 <br/><br/>
The Aquarius women live by their own rules and respect people who listen to their instincts and stay loyal to themselves. They will appreciate others' privacy and wish the same for others. They are a firm belief in peaceful co-occurrence and would never set their thoughts on others. 
<br/><br/>
Want to learn more about the Aquarius woman or more about the various aspects of your life? 
<br/><br/>
Go ahead and get the Janampatri report right away - this personalized report will provide you with details regarding your prospects in all the critical areas of your life.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
